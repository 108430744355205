import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors"
import { pVSuggestTableIcons, careGiverRequirementsIconBar } from "@/utils/iconBars"
import { hmsString } from "@/utils/helper"
import { timeLeft } from "@/models/feedbacks";
import { feedbackColorClass } from "@/models/feedbacks";

const getAction = (
	next: Function
) => {
	return async (data: any) => {
		// const href = `/leads/${data.item.attributes.taskable.id}`
		// await apiClient.patch(href, { usecase: 'assign_seller', seller_id: data.item.assignSeller.id })
		// Reload the data (tiles and table)
		next()
	}
}

export const getColumns = (
	data: Data,
	next: Function
) => {
	return [
		// {
		//   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
		//   key: "attributes.taskable.meta.tableRow.fullName",
		//   label: "Name",
		//   sortable: true,
		//   options: {hrefFunction: (data: any) => resourceUrl(data.item.attributes.taskable)}
		// },
		{
			component: () => import("@/components/shared/table/cells/LinkCell.vue"),
			key: "meta.tableRow.visorDesignation",
			label: "Stelle",
			sortable: true,
			options: {
				hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.visor),
				contentFunction: (data: any) => {
					const tr = data.item.meta.tableRow
					const regex = /[a-z]/
					if (regex.exec(tr.visorDesignation) === null) {
						return tr.crMergedNames || 'Name fehlt'
					} else {
						return tr.visorDesignation
					}
				}
			}
		},
		{
			component: () => import("@/components/shared/table/cells/ValueCell.vue"),
			key: "meta.tableRow.broadcasted",
			label: "Notfall",
			sortable: true,
			options: {
				colorInverted: true
				// cellClassFunction: (data:any) => {
				//   if (!data.item.meta.tableRow.broadcasted) return 'text-danger'
				//   return 'text-success'
				// },
			}
		},
		{
			component: () => import("@/components/shared/table/cells/ValueCell.vue"),
			key: "meta.tableRow.visorWorktimeHours",
			label: "Alter",
			tooltip: "Stunden an Arbeitszeit (nicht Nacht)",
			sortable: true,
			// options: {
			//   buttons: [
			//     {
			//       title: 'Timer',
			//       // icon: ['fa', 'check'],
			//       action: () => alert("Hey"),
			//     },
			//   ],
			// },
		},
		{
			component: () => import("@/components/shared/table/cells/ValueCell.vue"),
			key: "meta.tableRow.timeLeft",
			label: "Restzeit",
			tooltip: "Verbleibende Zeit für den Vorschlag",
			sortable: true,
			options: {
				contentFunction: (data: any) => {
					if (data.item.meta.tableRow.feedback.id) {
						return timeLeft(data.item.meta.tableRow.feedback)
					} else {
						const nsec = new Date().getTime()
						const laSec = Date.parse(data.item.meta.tableRow.visor.attributes.lastActivated)
						const diffSecs = 7200 - (nsec - laSec) / 1000
						return hmsString(diffSecs, true)
					}
				}
			}
		},
		{
			component: () => import("@/components/shared/table/cells/ValueCell.vue"),
			key: "meta.tableRow.locationInfo",
			label: "Ort",
			sortable: true,
		},
		{
			component: () => import("@/components/shared/table/cells/ValueCell.vue"),
			key: "meta.tableRow.budgetHigh",
			label: "Budget bis",
			sortable: true,
		},
		{
			component: () => import("@/components/shared/table/cells/ValueCell.vue"),
			key: "meta.tableRow.startDate",
			label: "Anreise",
			sortable: true,
			options: { type: 'date' }
		},
		{
			component: () => import("@/components/shared/table/cells/MultipleIconsCell.vue"),
			label: "1. Pflegebedürftige",
			title: "Icons",
			key: 'meta.tableRow.careReceiver.attributes',
			options: {
				icons: pVSuggestTableIcons
			}
		},
		{
			component: () => import("@/components/shared/table/cells/MultipleIconsCell.vue"),
			label: "Anforderungen BK",
			title: "Icons",
			key: 'meta.tableRow.careGiverRequirements',
			options: {
				icons: careGiverRequirementsIconBar
			}
		},
		{
			component: () => import("@/components/shared/table/cells/ValueCell.vue"),
			key: "meta.tableRow.sellerName",
			label: "Vertrieb",
			sortable: true,
		},
		// {
		//   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
		//   key: "meta.tableRow.suggestionCount",
		//   label: "BKS",
		//   sortable: true,
		// },
		// {
		//   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
		//   key: "meta.tableRow.comitted",
		//   label: "Abschlusssicher",
		//   sortable: true,
		// },
		// {
		//   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
		//   key: "attributes.taskable.meta.tableRow.createdAtSource",
		//   label: "Erstellt (quelle)",
		//   sortable: true,
		//   options: {type: 'date'}
		// },
		// {
		//   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
		//   key: "actions",
		//   label: "Aktionen",
		//   sortable: false,
		//   options: {
		//     buttons: [
		//       {
		//         title: 'Zur Ausschreibung',
		//         icon: ['fa', 'check'],
		//         action: getAction(next)
		//       },
		//     ]
		//   }
		// }
		{
			component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
			key: "actions",
			label: "Aktionen",
			sortable: false,
			options: {
				buttons: [
					(data: any) => {
						if (data.item.meta.tableRow.activeReservationPresent) {
							return null
						} else {
							return {
								title: 'Reservierung erstellen',
								icon: ['fas', 'calendar-plus'],
								action: next(data)
							}
						}
					}
				]
			}
		}
	]
}