import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";

const getAction = (
  next: Function
) => {
  return async (data: any) => {
    // const href = `/leads/${data.item.attributes.taskable.id}`
    // await apiClient.patch(href, { usecase: 'assign_seller', seller_id: data.item.assignSeller.id })
    // Reload the data (tiles and table)
    next()
  }
}

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.billNr",
    label: "Rechnungsnummer",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.year",
    label: "Jahr",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.month",
    label: "Monat",
  },
]}