
import { defineComponent, ref } from 'vue'
import IconLabel from '@/components/shared/IconLabel.vue'
import SummationList from '@/components/shared/SummationList.vue'

export default defineComponent({
  components: {
    IconLabel,
    SummationList,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    tableItem: {
      type: Object,
      required: true,
    },
  },

  setup(props: any, { emit }) {
    const modalVisible = ref(props.visible)
    const modalClose = () => {
      emit('done', false)
    }

    const address = props.tableItem.meta.tableRow.address
    const contractAddress = props.tableItem.meta.tableRow.contractAddress
    const priceTable = props.tableItem.meta.tableRow.priceTable

    return {
      modalVisible,
      modalClose,
      address,
      contractAddress,
      priceTable
    }
  },
})
