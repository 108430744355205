import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl, fullName } from "@/utils/dataExtractors"

// const getAction = (
//   resolve: boolean,
//   next: Function
// ) => {
//   return async (data: any) => {
//     next()
//   }
// }

export const getColumns = (
  next: Function,
  info: Function
) => {
  return [
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   key: "meta.tableRow.cgName",
    //   label: "Betreuungskraft",
    //   sortable: true,
    //   // options: {hrefFunction: (data: any) => `/care_givers/${data.item.meta.tableRow.careGiverId}`}
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   key: "meta.tableRow.agencyName",
    //   label: "Agentur",
    //   sortable: true,
    // },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "meta.tableRow.visorDesignation",
      label: "Stelle",
      sortable: true,
      options: {
        hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.visor)
      }
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "meta.tableRow.contract",
      label: "Vertrag",
      sortable: false,
      options: {
        contentFunction: (data: any) => 'Link',
        hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.contract)
      }
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "meta.tableRow.careGiver",
      label: "BK",
      sortable: true,
      options: {
        contentFunction: (data: any) => fullName(data.item.meta.tableRow.careGiver),
        hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.careGiverInstance)
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "meta.tableRow.sellerName",
      label: "Vertrieb",
      sortable: true,
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "meta.tableRow.cgiArrival",
      label: "Anreise",
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "meta.tableRow.cgiDeparture",
      label: "Abreise",
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      label: "Aktionen",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Info',
            icon: ['far', 'info-circle'],
            action: info()
          },
          {
            title: 'Bestätigen',
            icon: ['fad', 'user-check'],
            action: next(true)
          },
          // {
          //   title: 'Abbrechen',
          //   icon: ['fad', 'user-slash'],
          //   action: next(false)
          // }
        ]
      }
    }
    // {
    //   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
    //   key: "actions",
    //   label: "Aktionen",
    //   sortable: false,
    //   options: {
    //     buttons: [
    //       {
    //         title: 'Absenden',
    //         icon: ['fa', 'check'],
    //         action: getAction(next)
    //       },
    //     ]
    //   }
    // }
  ]
}