import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors"
import { pVSuggestTableIcons, careGiverRequirementsIconBar } from "@/utils/iconBars"
import { hmsString } from "@/utils/helper"
import { timeLeft } from "@/models/feedbacks";
import { feedbackColorClass } from "@/models/feedbacks";

const getAction = (
  next: Function
) => { return async (data: any) => { next() } }

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "meta.tableRow.designation",
      label: "Stelle",
      sortable: true,
      options: {
        hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.visor),
      }
    },
  ]
}