import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl, fullName } from "@/utils/dataExtractors"

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.cgName",
  //   label: "Betreuungskraft",
  //   sortable: true,
  //   // options: {hrefFunction: (data: any) => `/care_givers/${data.item.meta.tableRow.careGiverId}`}
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.createdAt",
  //   label: "Erhalten",
  //   sortable: true,
  //   options: {type: 'date'}
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.agencyName",
  //   label: "Agentur",
  //   sortable: true,
  // },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.visorDesignation",
    label: "Stelle",
    sortable: true,
    options: {
      // contentFunction: (data: any) => {
      //   const designation = data.item.meta.tableRow.visorDesignation
      //   const contractId = data.item.meta.tableRow.contract.id
      //   return contractId ? `Vertrag: ${designation}` : `Neukunde: ${designation}`
      // },
      hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.visor)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.rejectionReason",
    label: "Grund",
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.contractId",
  //   label: "Wechselvorschlag",
  //   options: {
  //     contentFunction: (data: any) => {
  //       console.log({data})
  //       return data.item.meta.tableRow.contract.id ? true : false
  //     },
  //   }
  // },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.careGiver",
    label: "BK",
    sortable: true,
    options: {
      contentFunction: (data: any) => fullName(data.item.meta.tableRow.careGiver),
      hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.careGiverInstance)
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
  //   key: "actions",
  //   label: "Aktionen",
  //   sortable: false,
  //   options: {
  //     buttons: [
  //       {
  //         title: 'Absenden',
  //         icon: ['fa', 'check'],
  //         action: getAction(next)
  //       },
  //     ]
  //   }
  // }
]}