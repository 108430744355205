import { lastTicketActivity } from "@/utils/dataExtractors";
import { modelCaseConversion } from '@/utils/apiDataHelper'
import { resourceUrl } from '@/utils/dataExtractors'
const viewAction = (
  next: Function
) => {
  return async (data: any) => {
    // Reload the data (tiles and table)
    next(data.item, 'view')
  }
}

const archiveAction = (
  next: Function
) => {
  return async (data: any) => {
    // Archive the ticket => for that we need to delete the task
    next(data.item, 'archive')
  }
}

export const getColumns = (
  next: Function
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.subject",
    label: "Subject",
    sortable: true,
    options: { hrefFunction: (data: any) => data.item.meta.tableRow.ticketLink }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.relatedName",
    label: "Senior/in",
    // sortable: true,
    options: {
      hrefFunction: (data: any) => {
        const type = data.item.attributes.taskable.attributes.ticketableType
        const ticketable_id = data.item.attributes.taskable.attributes.ticketableId
        if (['Lead', 'Household', 'Posting'].includes(type)) {
          return null
        } else {
          return `/${modelCaseConversion(type)}/${ticketable_id}`
        }
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.sellerName",
    label: "Vertrieb",
    sortable: true
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.taskable.attributes.category",
    label: "Kategorie",
    sortable: true
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Nachrichten",
    key: "meta.tableRow.messageCount",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Letzte Änderung",
    key: "meta.tableRow.lastChange",
    sortable: true,
    options: { type: 'datetime' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Prio",
    key: "attributes.priority",
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
  //   key: "actions",
  //   label: "Aktionen",
  //   sortable: false,
  //   options: {
  //     buttons: [
  //       {
  //         title: 'Ticket ansehen',
  //         icon: ['fa', 'search'],
  //         action: viewAction(next)
  //       },
  //       {
  //         title: 'Ticket Archivieren',
  //         icon: ['fa', 'archive'],
  //         action: archiveAction(next)
  //       },
  //     ]
  //   }
  // }
]
