
import { defineComponent, ref, computed, watch, getCurrentInstance } from 'vue'
import Tile from '@/components/Tile.vue'
import { Tile as TileModel } from '@/models/dashboard'
import { Contract } from '@/models/contracts'
import { Suggestion } from '@/models/suggestions'
import { Visor } from '@/models/visors'
import { Reservation } from '@/models/reservations'
import { Task } from '@/models/tasks'
import { Column } from '@/models/table'
import apiClient from '@/api/apiClient'
import {
  getRefreshData,
  initializeModal,
  addDefaultTaskColumns,
  addAction,
} from '@/view_scripts/dashboardUtils'
import {
  Data,
  Props,
  TileAction,
} from '@/views/shared/dashboard/dashboard.interface'
import { stringSort } from '@/utils/helper'
import { lastTicketActivity } from '@/utils/dataExtractors'
import { getColumns as pVSuggestColumns } from '@/views/provider/dashboard/p_v_suggest'
import { getColumns as pRExpiringColumns } from '@/views/provider/dashboard/p_r_expiring'
import { getColumns as pRNewsColumns } from '@/views/provider/dashboard/p_r_news'
import { getColumns as pVComittedColumns } from '@/views/provider/dashboard/p_v_comitted'
import { getColumns as pVNewsColums } from '@/views/provider/dashboard/p_v_news'
import { getColumns as pCSignColumns } from '@/views/provider/dashboard/p_c_sign'
import { getColumns as pCSwapnearColumns } from '@/views/provider/dashboard/p_c_swapnear'
import { getColumns as pTNotifiedColumns } from '@/views/provider/dashboard/p_t_notified'
import { getColumns as pSWaitColumns } from '@/views/provider/dashboard/p_s_wait'
import { getColumns as pSReviewColumns } from '@/views/provider/dashboard/p_s_review'
import { getColumns as pSAcceptedColumns } from '@/views/provider/dashboard/p_s_accepted'
import { getColumns as pSSwapAcceptedColumns } from '@/views/provider/dashboard/p_s_swap_accepted'
import { getColumns as pSRejectedColumns } from '@/views/provider/dashboard/p_s_rejected'
import { getColumns as pSSwaprejectedColumns } from '@/views/provider/dashboard/p_s_swaprejected'
import { getColumns as pAbCheck } from '@/views/provider/dashboard/p_ab_check'
import CgiAcceptedResponseModal from './dashboard/modals/CgiAcceptedResponseModal.vue'
import CgiSwapAcceptedResponseModal from './dashboard/modals/CgiSwapAcceptedResponseModal.vue'
import { confirm } from '@/utils/interactionModals'
import CgiInfoModal from './dashboard/modals/CgiInfoModal.vue'
// import TaskEditModal from "@/components/shared/modals/TaskEditModal.vue"

export default defineComponent({
  components: { Tile },
  props: { baseData: Array },
  setup(props: Props) {
    const root = getCurrentInstance().proxy
    //Tiles
    const orderedTileIds = [
      'p_cs_accepted',
      'p_cs_swapaccepted',
      'p_v_comitted',
      'p_v_suggest',
      'p_r_expiring',
      'p_t_notified',
      'p_cs_rejected',
      'p_cs_swaprejected',
      'p_cs_wait',
      'p_cs_swapwait',
      'p_c_swapnear',
      'p_c_sign',
      'p_c_nopdf',
      'p_ab_check',
    ]
    const orderedTiles = ref<TileModel[]>([])
    const activeTile = ref<TileModel>()
    const setActiveTile = (tile: TileModel) => {
      activeTile.value = tile
      processTileAction()
    }
    const tileInfo = ref<String | null>(null)
    const processTileAction = async () => {
      const tile = activeTile.value
      const response = await apiClient.get(tile?.links[tile.sysId])
      response.data.data = stringSort(
        response.data.data,
        (r) => Date.parse(r.attributes.createdAt),
        false
      )
      updateTableItems(response.data)
    }
    watch(
      () => props.baseData,
      (newValue) => {
        if (!newValue) return
        const copy = [...newValue]
        const startTile = copy.find((t) => t.sysId === 'p_v_suggest')
        if (startTile && !activeTile.value) setActiveTile(startTile)
        orderedTiles.value = copy.sort((a, b) => {
          return (
            orderedTileIds.indexOf(a.sysId) - orderedTileIds.indexOf(b.sysId)
          )
        })
      },
      { immediate: true }
    )

    // Table
    const showTable = ref(false)
    const tableName = ref()
    const columns = ref<Column[]>([])
    const modalColumns = ref<Column[]>([])
    const tableItems = ref<any[]>([])
    const refreshData = getRefreshData()

    const update = () => {
      refreshData()
      processTileAction()
    }

    // Modal
    const { modalType, modalProps, addReminderAction, addDestroyTaskAction } =
      initializeModal(root, update)
    const modalTableItems = ref<any[]>([])
    const modalClosed = (reload?: boolean) => {
      modalProps.value = { visible: false }
      modalType.value = undefined
      if (reload) {
        refreshData()
        processTileAction()
      }
    }

    const updateTableItems = (data: Data) => {
      let cols: any
      let nextAction
      let infoAction
      tileInfo.value = null
      switch (activeTile.value?.sysId) {
        case 'p_v_suggest':
          // console.log('dashboard data sugg', data)
          // const sorted = data.data.sort((a,b) => {
          //   const aVal = a.meta.tableRow.hhDesignation.toLowerCase()
          //   const bVal = b.meta.tableRow.hhDesignation.toLowerCase()
          //   if (aVal < bVal) {
          //     return -1
          //   } else if (aVal > bVal) {
          //     return 1
          //   } else {return 0}
          // })
          tableName.value = 'Betreuungskraft Vorschlagen'
          tableItems.value = data.data as Task<Visor>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pVSuggestColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_r_expiring':
          tableName.value = 'Reservierung läuft ab'
          tableItems.value = data.data as Task<Reservation>[]
          nextAction = (outer: any) => () => {
            refreshData()
            processTileAction()
          }
          cols = pRExpiringColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_r_news':
          tableName.value = 'Neuigkeiten zur Stelle'
          tableItems.value = data.data as Task<Reservation>[]
          nextAction = (outer: any) => () => {
            refreshData()
            processTileAction()
          }
          cols = pRNewsColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          addDestroyTaskAction(cols)
          break
        case 'p_r_exclusive':
          tableName.value = 'Exklusive Stellen'
          tableItems.value = data.data as Task<Reservation>[]
          nextAction = (outer: any) => () => {
            refreshData()
            processTileAction()
          }
          cols = pRExpiringColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_c_sign':
          tableName.value = 'Verträge ohne Unterschrift'
          tableItems.value = data.data as Task<Contract>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pCSignColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_c_nopdf':
          tableName.value = 'Fehlende Vertragsdokumente'
          tableItems.value = data.data as Task<Contract>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pCSignColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        // case 'p_c_arrival':
        //   tableName.value = 'Datum der Anreise nicht festgelegt'
        //   tableItems.value = (data.data as Task<Contract>[])
        //   nextAction = () => {
        //     refreshData()
        //     processTileAction()
        //   }
        //   cols = pCSignColumns(data as any, nextAction)
        //   addDefaultTaskColumns(cols)
        //   addReminderAction(cols)
        //   break
        case 'p_c_swapnear':
          tableName.value = 'BK Wechsel naht - Wechselvorschlag wird erbeten'
          tableItems.value = data.data as Task<Contract>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pCSwapnearColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_v_news':
          tableName.value = 'Neue Infos zur Stellenausschreibung'
          tableItems.value = data.data as Task<Contract>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pVNewsColums(data as any, nextAction)
          addReminderAction(cols)
          addDestroyTaskAction(cols)
          break
        case 'p_v_comitted':
          tileInfo.value =
            'Notfallstellen werden deaktiviert, sobale eine Kraft vorgestellt wurde. First come, first serve! Wenn Sie hier schnell sind, ist die Stelle fast sicher.'
          tableName.value = 'Abschlussichere Stellenausschreibung'
          tableItems.value = data.data as Task<Contract>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pVComittedColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_t_notified':
          tableName.value = 'Neukunde Ticket Mitteilungen'
          const tasks = data.data as any[]
          const sort = (task: any) =>
            lastTicketActivity(task.attributes.taskable)
          const prioTickets = stringSort(
            tasks.filter((t) => t.attributes.priority),
            sort,
            false
          )
          const normalTickets = stringSort(
            tasks.filter((t) => !t.attributes.priority),
            sort,
            false
          )
          tableItems.value = prioTickets.concat(normalTickets)
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pTNotifiedColumns(nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          addDestroyTaskAction(cols)
          break
        case 'p_t_customer_notified':
          tableName.value = 'Kunde Ticket Mitteilungen'
          const ctasks = data.data as any[]
          const csort = (task: any) =>
            lastTicketActivity(task.attributes.taskable)
          const cprioTickets = stringSort(
            ctasks.filter((t) => t.attributes.priority),
            csort,
            false
          )
          const cnormalTickets = stringSort(
            ctasks.filter((t) => !t.attributes.priority),
            csort,
            false
          )
          tableItems.value = cprioTickets.concat(cnormalTickets)
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pTNotifiedColumns(nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          addDestroyTaskAction(cols)
          break
        case 'p_cs_wait':
          tableName.value = 'Warten auf Kundenentscheidung zum Neuvorschlag'
          tableItems.value = data.data as Task<Contract>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pSWaitColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_cs_swapwait':
          tableName.value = 'Warten auf Kundenentscheidung zum Wechselvorschlag'
          tableItems.value = data.data as Task<Contract>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pSWaitColumns(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_cs_rejected':
          tableName.value = 'Neukunde: Betreuungskraft abgelehnt'
          tableItems.value = data.data as Task<Suggestion>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pSRejectedColumns(data as any, nextAction)
          // addReminderAction(cols)
          addDestroyTaskAction(cols)
          break
        case 'p_cs_cancelled':
          tableName.value = 'Pflegeeinsatz abgebrochen'
          tableItems.value = data.data as Task<Suggestion>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pSRejectedColumns(data as any, nextAction)
          // addReminderAction(cols)
          addDestroyTaskAction(cols)
          break
        case 'p_cs_swaprejected':
          tableName.value = 'Bestandskunde: Wechselkraft abgelehnt'
          tableItems.value = data.data as Task<Suggestion>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pSSwaprejectedColumns(data as any, nextAction)
          // addReminderAction(cols)
          addDestroyTaskAction(cols)
          break
        case 'p_cs_accepted':
          tableName.value = 'Vertragsabschluß - Betreuungskraft bestätigen!'
          tableItems.value = data.data as Task<Suggestion>[]
          nextAction = (confirm: boolean) => {
            return (item: any) => {
              modalType.value = CgiAcceptedResponseModal
              modalProps.value = {
                visible: true,
                suggestionData: item.item,
                confirm,
              }
            }
          }
          infoAction = () => {
            return (item: any) => {
              modalType.value = CgiInfoModal
              modalProps.value = {
                visible: true,
                tableItem: item.item,
              }
            }
          }
          // nextAction = (item: any) => {
          //   console.log('item: ', item, 'resolve', resolve)
          //   modalType.value = CgiAcceptedResponseModal
          //   modalProps.value = {
          //     visible: true,
          //     suggestionData: item.item.attributes.taskable,
          //     resolve
          //   }
          // }
          cols = pSAcceptedColumns(nextAction, infoAction)
          addReminderAction(cols)
          addDefaultTaskColumns(cols)
          break
        case 'p_cs_swapaccepted':
          tableName.value = 'Wechselkraft bestätigen!'
          tableItems.value = data.data as Task<Suggestion>[]
          nextAction = (confirm: boolean) => {
            return (item: any) => {
              // modalType.value = CgiSwapAcceptedResponseModal
              modalType.value = CgiAcceptedResponseModal
              modalProps.value = {
                visible: true,
                suggestionData: item.item,
                // suggestionData: item.item.attributes.taskable,
                // currentSegmentData: item.item.meta.tableRow.currentSegment,
                confirm,
              }
            }
          }
          infoAction = () => {
            return (item: any) => {
              modalType.value = CgiInfoModal
              modalProps.value = {
                visible: true,
                tableItem: item.item,
              }
            }
          }
          // cols = pSSwapAcceptedColumns(data as any, nextAction)
          cols = pSAcceptedColumns(nextAction, infoAction)
          addReminderAction(cols)
          addDefaultTaskColumns(cols)
          break
        case 'p_ab_check':
          tableName.value = 'Rechnung Überprüfen'
          tableItems.value = data.data as Task<Suggestion>[]
          nextAction = () => {
            refreshData()
            processTileAction()
          }
          cols = pAbCheck(data as any, nextAction)
          addDefaultTaskColumns(cols)
          addReminderAction(cols)
          break
        case 'p_cs_review':
          tableName.value = 'Bitte maschinellen Vorschlag prüfen'
          tableItems.value = data.data as Task<Contract>[]
          nextAction = (accepted: boolean) => {
            const confirmMessage = `Der Vorschlag wird ${
              accepted ? 'frei gegeben' : 'abgebrochen'
            }.`
            const patchData = {
              attributes: accepted
                ? {
                    stage: 'Neu',
                  }
                : {
                    stage: 'Abgebrochen',
                    cancel_reason: 'matcher_fault',
                  },
            }
            return async (data: any) => {
              const href = `/suggestions/${data.item.meta.tableRow.suggestion?.id}`
              const confirmation = await confirm(root, confirmMessage)
              if (confirmation) {
                apiClient.patch(href, patchData).then((r) => {
                  refreshData()
                  processTileAction()
                })
              }
            }
          }
          cols = pSReviewColumns(data as any, nextAction)
          // addDefaultTaskColumns(cols)
          // addReminderAction(cols)
          break
        default:
          throw Error(
            `This Tile's sysId: ${activeTile.value?.sysId} does not exist.`
          )
      }
      columns.value = cols
      showTable.value = true
    }

    return {
      orderedTiles,
      activeTile,
      setActiveTile,
      tableName,
      tableItems,
      showTable,
      columns,
      modalType,
      modalProps,
      modalClosed,
      modalColumns,
      modalTableItems,
      tileInfo
    }
  },
})
