import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl, fullName } from "@/utils/dataExtractors"

const confirmAction = (
  next: Function
) => {
  return async (data: any) => {
    const href = `/leads/${data.item.attributes.taskable.id}`
    await apiClient.patch(href, { usecase: 'assign_seller', seller_id: data.item.assignSeller.id })
    // Reload the data (tiles and table)
    next()
  }
}

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.cgName",
  //   label: "Betreuungskraft",
  //   sortable: true,
  //   // options: {hrefFunction: (data: any) => `/care_givers/${data.item.meta.tableRow.careGiverId}`}
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.createdAt",
  //   label: "Erhalten",
  //   sortable: true,
  //   options: {type: 'date'}
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.agencyName",
  //   label: "Agentur",
  //   sortable: true,
  // },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.visorDesignation",
    label: "Stelle",
    sortable: true,
    options: {
      // contentFunction: (data: any) => {
      //   const tr = data.item.meta.tableRow
      //   const type = tr.contractPresent ? 'Vertrag' : 'Neukunde'
      //   return `${type}: ${tr.visorDesignation}`
      // },
      hrefFunction: (data: any) => {
        const tr = data.item.meta.tableRow
        return tr.contractPresent ? resourceUrl(tr.contract) : resourceUrl(tr.visor)
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.suggestion",
    label: "Vorschlag",
    sortable: true,
    options: {
      contentFunction: (data: any) => 'zum Vorschlag',
      hrefFunction: (data: any) => {
        const tr = data.item.meta.tableRow
        return resourceUrl(tr.suggestion)
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.careGiver",
    label: "BK",
    sortable: true,
    options: {
      contentFunction: (data: any) => fullName(data.item.meta.tableRow.careGiver),
      hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.careGiverInstance)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.presentedAt",
    label: "Vorgestellt",
    options: {
      type: 'datetime'
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.cgiArrival",
    label: "Anreise",
    options: {
      type: 'date'
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.contractId",
  //   label: "Wechselvorschlag",
  //   options: {
  //     contentFunction: (data: any) => data.item.meta.tableRow.contractPresent,
  //   }
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.suggestionCount",
  //   label: "#",
  //   sortable: true,
  // },
  {
    component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
    key: "actions",
    label: "Aktionen",
    sortable: false,
    options: {
      buttons: [
        {
          title: 'Bestätigen',
          icon: ['fa', 'check'],
          action: next(true)
        },
        {
          title: 'Absagen',
          icon: ['fa', 'times'],
          action: next(false)
        }
      ]
    }
  }
]}