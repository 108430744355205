import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors"

const getAction = (
  next: Function
) => {
  return async (data: any) => {
    // const href = `/leads/${data.item.attributes.taskable.id}`
    // await apiClient.patch(href, { usecase: 'assign_seller', seller_id: data.item.assignSeller.id })
    // Reload the data (tiles and table)
    next()
  }
}

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  // {
  //   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
  //   key: "attributes.taskable.meta.tableRow.fullName",
  //   label: "Name",
  //   sortable: true,
  //   options: {hrefFunction: (data: any) => resourceUrl(data.item.attributes.taskable)}
  // },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.visorDesignation",
    label: "Vertrag",
    sortable: true,
    options: {hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.contract)}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.startDate",
    label: "Start",
    sortable: true,
    options: {
      type: 'date'
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.suggestionCount",
  //   label: "Vorschläge",
  //   sortable: true,
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.committed",
  //   label: "Abschlusssicher",
  //   sortable: true,
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
  //   key: "actions",
  //   label: "Aktionen",
  //   sortable: false,
  //   options: {
  //     buttons: [
  //       {
  //         title: 'Zur Ausschreibung',
  //         icon: ['fa', 'check'],
  //         action: getAction(next)
  //       },
  //     ]
  //   }
  // }
]}